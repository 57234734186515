<template>
    <div>
        <ul>
            <li
                v-for="(item, key) in menus"
                v-show="item.show"
                :key="key"
                class="cursor-pointer pl-5 py-3"
                :class="item.value === $route.name ? 'bg-red-500 bg-opacity-20 rounded-lg cursor-pointer pl-5 py-3' : ''"
                @click="$router.push({ name: item.value })">
                {{ item.name }}
            </li>
        </ul>
    </div>
</template>
<script>
import { mapState } from "vuex";

export default {
    name: "SideBar",
    computed: {
        ...mapState("permissionStore", ["isAdmin"]),
        routers() {
            return this.$route.meta.params.sideItems ? this.$route.meta.params.sideItems : [];
        },
        menus() {
            return [
                {
                    name: "路由權限列表",
                    value: "permission_routes",
                    show: this.checkPermission("permission_routes"),
                },
                {
                    name: "角色列表",
                    value: "permission_roles",
                    show: this.checkPermission("permission_roles"),
                },
                {
                    name: "人員列表",
                    value: "permission_users",
                    show: this.checkPermission("permission_users"),
                },
            ];
        },
    },
    methods: {
        checkPermission(key) {
            if (this.isAdmin) {
                return true;
            }
            if (this.routers.includes(key)) {
                return true;
            }
            return false;
        },
        // 判斷有沒有此路由並導向
        goFirstTab() {
            this.$nextTick(() => {
                const idx = this.menus.findIndex((i) => i.value === this.$route.name);
                if (idx !== -1 && this.menus[idx].show === false) {
                    const idx2 = this.menus.findIndex((i) => i.show === true);
                    if (idx2 !== -1) {
                        this.$router.push({ name: this.menus[idx].value });
                    }
                    this.$router.push({ name: this.menus[idx2].value });
                }
            });
        },
    },
    mounted() {
        // 導致有權限的第一頁
        this.goFirstTab();
    },
};
</script>
